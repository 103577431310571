<template>
	<!-- 底部 -->
    <div class="index_footer">
		<div class="foot_con fontsize16">
			<div class="w1200 hxinfo">
				<div class="hlinfo_box">
					<div class="datalist">
						<div class="textname fontsize16">关注我们</div>
						<div class="itemobj">
							<el-popover
							  placement="top"
							  trigger="hover">
							  <div style="margin: 0 auto; width: 168px;height: 168px;"><img src="../../assets/static/sph.jpg" alt="视频号" class="img100"></div>
							  <div slot="reference" class="pointer"><img class="img100" src="../../assets/static/wb.png"/></div>
							</el-popover>
						</div>
						<div class="itemobj">
							<el-popover
							  placement="top"
							  trigger="hover">
							  <div style="margin: 0 auto; width: 168px;height: 168px;"><img src="../../assets/static/dyh.jpg" alt="抖音" class="img100"></div>
							  <div slot="reference" class="pointer"><img class="img100" src="../../assets/static/dy.png"/></div>
							</el-popover>
						</div>
						<div class="itemobj">
							<el-popover
							  placement="top"
							  trigger="hover">
							  <div style="margin: 0 auto; width: 168px;height: 168px;"><img src="../../assets/static/gzh.jpg" alt="微信公众号" class="img100"></div>
							  <div slot="reference" class="pointer"><img class="img100" src="../../assets/static/wx.png"/></div>
							</el-popover>
						</div>
						<!-- <div class="itemobj">
							<el-popover
							  placement="top"
							  trigger="hover">
							  <div style="margin: 0 auto; width: 168px;height: 168px;"><img src="../../assets/static/kefu.png" alt="在线客服" class="img100"></div>
							  <div slot="reference" class="pointer"><img class="img100" src="../../assets/static/xl.png"/></div>
							</el-popover>
						</div> -->
					</div>
					<div class="centerbox fontsize16">
						<div class="box1">
							<div class="text1" @click="menuClick('webschool')">河狸课堂</div>
							<div class="text1" @click="menuClick('questionbank')">河狸训练营</div>
							<div class="text1" @click="menuClick('mygroup')">河狸社团</div>
							<div class="text1" @click="menuClick('match')">赛事与考试</div>
						</div>
						<div class="box1">
							<div class="text1" @click="menuClick('mydiscuss')">河狸讨论区</div>
							<div class="text1" @click="menuClick('kefu')">在线客服</div>
							<div class="text1" @click="menuClick('mine')">个人中心</div>
							<div class="text1"></div>
						</div>
						<div class="imgbox"><img class="imgicon" src="../../assets/logo/heliheli.png"/></div>
					</div>
					<div class="info_two">
						<a href="http://beian.miit.gov.cn/" target="_blank">备案号：苏ICP备2024079793号</a>
						<!-- <div class="text">地址： 江苏省苏州市常熟经济技术开发区苏州路40号</div> -->
					</div>
				</div>
			</div>
		</div>
		<!-- 查看大图 -->
		<el-dialog :visible.sync="dialogimgVisible" center title="在线客服">
		    <div style="margin: 0 auto; width: 268px;height: 268px;">
				<img src="../../assets/static/kefu.png" alt="在线客服" class="img100">
			</div>
		</el-dialog>
    </div>
</template>
<script>
export default {
	name: 'Footer',
	data() {
		return {
			current: 0, // 当前索引
			dateyear: '',
			dialogimgVisible: false, // 控制大图
		};
	},
	created() {
		this.dateyear = new Date().getFullYear();
	},
	methods: {
		See(e) {
			// window.location.href = e;
			window.open(e,'_blank');
		},
		menuClick(url) {
			console.log('我点击了menu');
			if(url){
				if(url=="mine"){
					this.$router.push({
						name: "mine",
						params:{
						    type:"order"
						}
					});
				}else if(url=="kefu"){
					//客服
					this.dialogimgVisible = true
				}else{
					this.$router.push({
						name: url
					});
				}
			}else{
				this.$message.info("此模块暂未开放")
			}
		},
	}
};
</script>
<style lang="scss" scoped>
.index_footer{
	width: 100%;
	background: #FFFFFF;
	.foot_con{
		width: 100%;
		.hlinfo_box{
			padding: 10px 0;
			.datalist{
				height: 50px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #F2F2F2;
				.textname{
					color: #000;
					margin-right: 8px;
				}
				.itemobj{
					width: 32px;
					height: 32px;
					margin-left: 16px;
					cursor: pointer;
				}
			}
			.centerbox{
				width: 100%;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #F2F2F2;
				.box1{
					width: 176px;
					padding: 8px 0;
					.text1{
						height: 33px;
						line-height: 33px;
						width: 100%;
						color: #666;
						cursor: pointer;
					}
				}
				.imgbox{
					margin-left: auto;
					.imgicon{
						width: 167px;
						height: 53.605px;
					}
				}
			}
			.info_two{
				padding: 15px 0;
				display: flex;
				align-items: center;
				color: #666;
				a{
					color: #666;
				}
				.text{
					margin-left: 68px;
				}
			}
		}

	}
}
</style>
